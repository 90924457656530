// IMPORTANT: These imports with side effects must be kept in-sync
// with the sideEffects configuration in package.json.
// See https://webpack.js.org/guides/tree-shaking/.
import "./cdn";
import "./vendor/index";
import "./styles/main.styl";
import "./styles/vendor/spectrum.css";
import "./bindings/index";
import "./extenders/index";
import "./lib/components/index";
import appTemplate from "@/app-layout.pug";
import { modalManager } from "@/lib/managers/modal-manager";
import { dragManager } from "@/lib/managers/drag-manager";
import { app } from "@/views/app";
import { MainRoutes } from "@/routes/main-routes";
import { router } from "@/lib/router";
import $ from "jquery";
import { applyBindings } from "knockout";
import Bugsnag from "@bugsnag/js";

if (!(window as any).LC_SUPPORTED_BROWSER) {
   throw Error("Unsupported browser detected! Not starting application.");
}

$(document).ready(function () {
   // Create the layout DOM.
   $("body").html(appTemplate());
   // Needed for subrouting in Procore
   $("body").addClass("labor-chart-click-listener");

   Bugsnag.start({
      apiKey: (window as any).config.WFP_BUGSNAG_API_KEY,
      enabledReleaseStages: ["production", "sandbox", "training", "staging", "dev"],
      enabledBreadcrumbTypes: ["error", "log", "navigation", "request", "user"],
      releaseStage: process.env.NODE_ENV,
      appType: "lc-web-app",
   });

   modalManager.initialize();
   dragManager.initDragula();

   // Bind the starting app view model and check for mobile.
   const mobileOptions = [
      "Mobile",
      "iPhone",
      "iPod",
      "IEMobile",
      "Windows Phone",
      "Android",
      "Blackberry",
      "webOS",
      "Tablet",
      "iPad",
      "Nexus 7",
      "Nexus 10",
   ];
   for (const option of mobileOptions) {
      if (navigator.userAgent.match(option)) {
         $("body").addClass("mobile");
         break;
      }
   }

   applyBindings(app);
   MainRoutes();

   // This must be last because everything needs to be initialized before listen is called.
   return router.listen();
});
