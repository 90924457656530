 ko.extenders.maskCreditCard = (target, options) ->
 # create a writeable computed observable to intercept writes to our observable
     result = ko.computed
         read: -> return target(),
         write: (newValue) ->
             newVal = ko.unwrap(newValue)
             rawVal = options.rawVal
             return target(newVal) unless newVal?
             numbers = newVal.replace(/\D/g,'')
             trimmedNumbers = if numbers.length > 16 then numbers.substring(0, 16) else numbers
             rawVal(trimmedNumbers)
             dashed = trimmedNumbers.replace(/(\d{4}(?!\s))/g, "$1-")
             formatted = if dashed.length > 19 then dashed.substring(0, 19) else dashed
             target(formatted)

     # initialize with current value to make sure it is rounded appropriately
     result(target());

     # return the new computed observable
     return result;