ko.observable.fn['withPausing'] = ->
   @notifySubscribers = =>
      if (!@pauseNotifications)
         ko.subscribable.fn.notifySubscribers.apply(this, arguments)

   @sneakyUpdate = (newVal) =>
      this.pauseNotifications = true
      this(newVal)
      this.pauseNotifications = false

   return @